exports.components = {
  "component---src-components-films-gallery-js": () => import("./../../../src/components/FilmsGallery.js" /* webpackChunkName: "component---src-components-films-gallery-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-film-[id]-js": () => import("./../../../src/pages/film/[id].js" /* webpackChunkName: "component---src-pages-film-[id]-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stills-[id]-js": () => import("./../../../src/pages/stills/[id].js" /* webpackChunkName: "component---src-pages-stills-[id]-js" */),
  "component---src-templates-film-project-js": () => import("./../../../src/templates/FilmProject.js" /* webpackChunkName: "component---src-templates-film-project-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

