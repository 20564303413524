export default {
  colors: {
    blue: "#08005C",
    green: "#83982F",
    snow: "#E7E7E7",
  },
  fonts: {
    body: "system-ui, sans-serif",
    heading: "system-ui, sans-serif",
    monospace: "Menlo, monospace",
  },
};
